<template>
  <BaseHeader @switch="switchSide"></BaseHeader>
  <el-dialog
    v-model="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="closeButton"
    @close="closeDialog"
    append-to-body
    width="70%"
    :title="noticeDetail.title">
    <div v-if="noticeDetail.type == 'merchantSignIn'">
      <el-space direction="vertical" alignment="start">
        <el-image :src="noticeDetail.image" class="max-w-70" />        
      </el-space>
      <p class="mt-2"><strong>报名时间:</strong> {{ noticeDetail.enroll_start_time }} - {{ noticeDetail.enroll_end_time }}</p>
      <p class="mt-2"><strong>活动说明:</strong></p>
      <div v-html="noticeDetail.content" />
    </div>
    <div v-else-if="noticeDetail.type == 'merchantSignInResult'">
      <p>{{ noticeDetail.content }}</p>
    </div>
    <div v-else>
      <div v-html="noticeDetail.detail" />
      <div style="margin-top: 10px;" v-if="noticeDetail.pdf_name">
        附件：
        <el-link :href="noticeDetail.pdf_path" target="_blank" type="primary">
          {{noticeDetail.pdf_name}}
        </el-link>
      </div>
    </div>
      
      <template #footer v-if="isConfirm">
        <div class="flex justify-center" v-if="noticeDetail.type != 'merchantSignInResult' && noticeDetail.type != 'merchantSignIn'">
          <el-button :disabled="dialogTimmer > 0" @click="dialogVisible = false" type="primary">
            我知道啦{{dialogTimmerFomat(dialogTimmer)}}
          </el-button>
        </div>
        <div class="flex justify-center" v-else-if="noticeDetail.type == 'merchantSignInResult'">
          <el-button @click="() => { dialogVisible = false; router.push('/main/notice') }" type="primary">
            查看
          </el-button>
        </div>
        <div class="flex justify-center" v-else-if="noticeDetail.type == 'merchantSignIn'">
          <el-button @click="openEnrollDialog" type="primary">
            报名
          </el-button>
          <el-button @click="dialogVisible = false">
            取消
          </el-button>
        </div>
      </template>
  </el-dialog>
  <div class="overlay" v-if="sideBarToggle" @click="switchSide"></div>
  <SideBar :status="sideBarToggle"></SideBar>
  <PageContent></PageContent>
  <el-backtop :right="50" :bottom="180"></el-backtop>
  <EnrollDialog 
    v-model="enrollDialogVisible" 
    :activity-id="noticeDetail.activity_id"
    @success="handleEnrollSuccess"
  />
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch, h } from 'vue';
import SideBar from './SideBar.vue';
import PageContent from './PageContent.vue';
import BaseHeader from './BaseHeader.vue';
import EnrollDialog from '@/components/notice/EnrollDialog.vue';
import {
  notReadForciblyList,
  getAnnouncementDetail,
  getActivityAnnouncement,
  getActivityAuditResult,
  confirmAuditResult,
  confirmActivityAnnouncement,
  enrollActivity
} from '@/model/notice/notice.js'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
const route = useRoute()
const router = useRouter()
const token = ref(JSON.parse(sessionStorage.getItem('token')) || '')
const noticeDetail = ref({})

const dialogVisible = ref(false)
// 标记报名弹窗是否打开
const enrollDialogVisible = ref(false)

const enrollForm = reactive({
  id: '',
  remark: ''
})

// 报名loading
const enrollLoading = ref(false)


const dialogTimmer = ref('')
const dialogTimmerFomat = (value) => {
  if (value) {
    return `(${value})`
  }
  return ''
}
const timmer = (time) => {
  dialogTimmer.value = time
  if (time) {
    setTimeout(() => timmer(time - 1), 1000)
  }
}

const sideBarToggle = ref(false);
const switchSide = () => {
  sideBarToggle.value = !sideBarToggle.value;
};
// 提醒并确认
const isConfirm = computed(() => noticeDetail.value.status == 2 || noticeDetail.value.status == 1 || noticeDetail.value.type == 'merchantSignIn' || noticeDetail.value.type == 'merchantSignInResult')


// 弹窗关闭按钮
const closeButton = computed(() => noticeDetail.value.type == 'merchantSignInResult' || noticeDetail.value.type == 'merchantSignIn')

watch(noticeDetail, (detail) => {
  if (Number(detail.status) == '1' || Number(detail.status) == '2' || detail.type == 'merchantSignInResult' || detail.type == 'merchantSignIn') {
    dialogVisible.value = true

    if (Number(detail.status) == '2') {
      timmer(5)
    }
  }
})

const closeDialog = async () => {
  try {
    // 如果type为merchantSignIn 执行确认活动公告接口
    if (noticeDetail.value.type == 'merchantSignIn') {
      await confirmActivityAnnouncement(noticeDetail.value.id)
    } else if (noticeDetail.value.type == 'merchantSignInResult') { // 如果type为merchantSignInResult 执行查看活动结果接口
      await confirmAuditResult(noticeDetail.value.id)
    } else {
      await getAnnouncementDetail(noticeDetail.value.id)
    }
  } catch(error) {
    console.log(error)
  }
  // 重新请求
  notReadForciblyListFetch()
}

const openEnrollDialog = () => {
  enrollDialogVisible.value = true
}

const handleEnrollSuccess = () => {
  dialogVisible.value = false
}

// 确认报名
const confirmEnroll = async () => {
  try {
    await ElMessageBox.confirm('报名后官方客服将联系您确认具体合作细节,请保持手机畅通！', '确认报名', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      // 使用h方法渲染message
      message: h('div', { style: { textAlign: 'center', marginBottom: '16px' } }, [
        h('div', null, '是否确认报名活动？'),
        h('div', null, '报名后官方客服将联系您确认具体合作细节,请保持手机畅通！')
      ])
    })
    enrollLoading.value = true
    await enrollActivity(enrollForm).then(res => {
      if (!res) return
      ElMessage.success('报名成功')
      enrollDialogVisible.value = false
      dialogVisible.value = false
      enrollLoading.value = false
    }).finally(() => {
      enrollLoading.value = false
    })
  } catch (error) {
    if (error !== 'cancel') {
      ElMessage.error('报名失败')
    }
  }
}



const notReadForciblyListFetch = async() => {
  if (token.value) {
    const signInResults = await getActivityAuditResult()
    console.log('signInResults', signInResults)
    // 为signInResults的每项加上type 为merchantSignIn
    signInResults.forEach(item => {
      item.type = 'merchantSignInResult'
    })

    const merchantSignInList = await getActivityAnnouncement('1')
    console.log('merchantSignInList', merchantSignInList)
    // 为merchantSignInList的每项加上type 为merchantSignIn
    merchantSignInList.forEach(item => {
      item.type = 'merchantSignIn'
    })
    console.log(merchantSignInList,1111);
    
    const ids = merchantSignInList.map(item => item.id)
    let result = await notReadForciblyList(ids)
    result = [...signInResults, ...merchantSignInList, ...result]
    console.log('result', result)
    if (result && result.length) {
      noticeDetail.value = result[0]
    }
  }
}

watch(route, () => {
  notReadForciblyListFetch()
}, { deep: true, immediate: true })

</script>

<style scoped>
.sub-nav {
  box-sizing: border-box;
  border-bottom: 1px solid var(--ep-border-color);
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  padding: 0 var(--container-y-padding);
  background-color: var(--bg-color);
  z-index: var(--sub-nav-z-index);
}
@media screen and (min-width: 1440px) {
  .sub-nav {
    display: none !important;
  }
  .overlay {
    display: none !important;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: var(--overlay-z-index);
}
.max-w-70 {
  max-width: 70%;
}
</style>
